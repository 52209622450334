import { Bodies, Body, Engine, Events, Mouse, Pairs, Query, Render, Vector, World, Runner } from 'matter-js';
import * as notify from 'pling';

import Manager from '../manager';
import Checkpoint from './checkpoint';
import Platform from './platform';
import Saw from './saw';
import Spawner from './spawner';
import Spike from './spike';

export let engine: Engine;
export let render: Render;
export let runner: Runner;

export default class Game {
  public width: number;
  public height: number;

  public container: HTMLDivElement;
  public context: CanvasRenderingContext2D;

  public zoomScale: number = 1;
  public bodies: Bodies;

  public levelFinished: boolean;

  public player: Matter.Body;
  public playerSensor: Matter.Body;

  public mouse: Mouse;

  public debug: boolean;

  public keys: boolean[];
  public released: number[];

  public running: boolean = true;

  public manager: Manager;

  public bodyA: Body;
  public bodyB: Body;
  public bodyC: Body;
  public bodyD: Body;

  public entities: any[];

  public selected: null | Body;
  public initialSelected: null | Body;

  public resetTimeout;

  public playerCollidingPairs: Pairs[] = [];

  // fps should be locked at:
  public fps = 60;
  // init of now to base value
  public now = Date.now();
  // setting then to base value
  public then = this.now;
  // what I want time in between frames to be
  public interval = 1000 / this.fps;
  // init of delta
  public delta = 0;

  constructor(opts: { debug?: boolean; container?: HTMLDivElement; manager: Manager } = { container: undefined, manager: undefined, debug: false }) {
    this.height = opts.container.clientHeight;
    this.width = opts.container.clientWidth;

    this.debug = opts.debug;

    this.container = opts.container;
    this.manager = opts.manager;

    this.keys = [];
    this.released = Array(500).fill(Date.now());
    this.entities = [];

    this.init();
  }

  public init() {
    engine = Engine.create({});
    render = Render.create({
      engine,
      element: this.container,
      options: {
        width: this.width,
        height: this.height,
        ...(this.debug ? { wireframes: true } : { wireframes: false }),
        ...(this.debug && { showCollisions: true }),
        ...(this.debug && { showDebug: true }),
        ...(this.debug && { showVelocity: true }),
        ...(this.debug && { showAxes: true }),
        background: '#fff',
        wireframeBackground: '#b1b00044',
        // showPositions: true,
        // showAngleIndicator: true,
      },
    } as any);

    this.mouse = Mouse.create(render.canvas);
    this.mouse.pixelRatio = 1;

    runner = (Runner as any).create({});

    Events.on(render, 'afterRender', () => {
      if (!this.debug) return;
      render.context.font = '20px Arial';
      render.context.fillStyle = 'white';
      render.context.fillText(`Reutemeteut ${this.running ? 'Playing' : 'Paused'} - Level ${this.manager.levelIndex}`, this.width - 400, 20);
    });

    Events.on(engine, 'collisionStart', event => {
      event.pairs.forEach(pair => {
        const { bodyA, bodyB } = pair;
        // if ((levels.includes(bodyA) && bodyB === this.player) || (bodyA === this.player && levels.includes(bodyB))) {
        if ((bodyB === this.player || bodyA === this.player) && (!bodyB.isSensor && !bodyA.isSensor)) {
          if ((pair as any).activeContacts.length > 0) {
            let normalPosX = (pair as any).activeContacts[0].vertex.x;
            let normalPosY = (pair as any).activeContacts[0].vertex.y;

            if ((pair as any).activeContacts.length === 2) {
              normalPosX = ((pair as any).activeContacts[0].vertex.x + (pair as any).activeContacts[1].vertex.x) / 2;
              normalPosY = ((pair as any).activeContacts[0].vertex.y + (pair as any).activeContacts[1].vertex.y) / 2;
            }

            const angle = Math.atan2(normalPosY - this.player.position.y, normalPosX - this.player.position.x);
            const normalizedAngle = Math.atan2(Math.sin(angle), Math.cos(angle));

            if (normalizedAngle > -Math.PI / 8 || normalizedAngle < -Math.PI + Math.PI / 8) {
              if ((this.player as any).jumps <= 0) (this.player as any).jumps = 1;
              (this.player as any).canJump = true;
            }
          }

          const obstacle = bodyB === this.player ? bodyA : bodyB;
          this.playerCollidingPairs.push(pair);
          const obstacles = this.entities.filter(entity => entity instanceof Saw || entity instanceof Spike).map(entity => entity.body);
          // weird thing because it only collides with the individual part (hence we have to check parent)
          if (obstacles.includes(obstacle) || obstacles.includes(obstacle.parent)) {
            if (process.env.mode === 'production')
              notify({
                key: '6e5279b242a9b9670e5a231dc96410b93a7b527d4ce513b168b9f518e7bcf9fb',
                title: `Dead: Level ${this.manager.levelIndex}`,
                description: JSON.stringify({ timestamp: engine.timing.timestamp / 1000, username: this.manager.profile.username, uuid: this.manager.profile.uuid }),
              });
            this.die();
          }
        }
      });
    });

    Events.on(engine, 'collisionEnd', event => {
      event.pairs.forEach(pair => {
        const { bodyA, bodyB } = pair;
        if (bodyB === this.player || bodyA === this.player) {
          const floor = bodyB === this.player ? bodyA : bodyB;

          this.playerCollidingPairs.splice(this.playerCollidingPairs.indexOf(pair), 1);
        }
      });
    });

    Events.on(engine, 'collisionActive', event => {
      event.pairs.forEach(pair => {
        const { bodyA, bodyB } = pair;
        if (bodyB === this.player || bodyA === this.player) {
          const floor = bodyB === this.player ? bodyA : bodyB;

          if (!floor.isSensor) {
            const absoluteNormalizedAngle = Math.abs(Math.atan2(Math.sin(floor.angle), Math.cos(floor.angle)));
            const offset = Math.PI / 25;
            if (
              (absoluteNormalizedAngle >= 0 && absoluteNormalizedAngle <= offset) ||
              (absoluteNormalizedAngle >= Math.PI - offset && absoluteNormalizedAngle <= Math.PI) ||
              (absoluteNormalizedAngle >= Math.PI - offset && absoluteNormalizedAngle <= Math.PI + offset) ||
              (absoluteNormalizedAngle >= 2 * Math.PI - offset && absoluteNormalizedAngle <= 2 * Math.PI)
            ) {
              if (!(this.keys[37] || this.keys[38] || this.keys[39] || this.keys[40])) {
                const x = this.player.velocity.x;
                const finalX = Math.abs(x * 0.9) < 0.01 ? 0 : x * 0.9;

                Body.setVelocity(this.player, { x: finalX, y: this.player.velocity.y });
              }
            }
          }

          const checkpoints = this.entities.filter(entity => entity instanceof Checkpoint).map(entity => entity.body);
          if (checkpoints.includes(bodyB) || checkpoints.includes(bodyA)) {
            // handle checkpoint logic
            if (engine.timing.timestamp - pair.timeCreated > 100 && !this.levelFinished) {
              this.levelFinished = true;

              if (process.env.mode === 'production') {
                notify({
                  key: 'bbd579c0d69fca29e4b7c69acbdc7c435c0d007ad8121119a6ab1d25aced14c2',
                  title: `Reutemeteut: Level ${this.manager.levelIndex} finished`,
                  description: JSON.stringify({ timestamp: engine.timing.timestamp / 1000, username: this.manager.profile.username, uuid: this.manager.profile.uuid }),
                });

                if (this.manager.levelIndex === this.manager.levels.length - 1) {
                  notify({
                    key: '913b6cddf2f94d5a775c81a49c3932e1d0bc44917d91d94bd9db11b9df1a0f59',
                    title: `Reutemeteut finisher: ${this.manager.profile.username}`,
                    description: JSON.stringify({ username: this.manager.profile.username, uuid: this.manager.profile.uuid }),
                  });
                }
              }
              this.manager.finish();
            }
          }
        }
      });
    });

    Events.on(engine, 'afterTick', event => {});
    Events.on(engine, 'beforeTick', event => {
      this.entities.forEach(entity => entity.update && entity.update(event));
      // setting max velocity
      if (this.player) {
        const camera = {
          x: (render.bounds.max.x + render.bounds.min.x) / 2,
          y: (render.bounds.max.y + render.bounds.min.y) / 2,
        };

        (Render as any).lookAt(
          render,
          {
            x: camera.x + (this.player.position.x - camera.x) * 0.01 * runner.delta,
            y: camera.y + (this.player.position.y - camera.y) * 0.01 * runner.delta,
          },
          {
            x: render.options.width / this.zoomScale,
            y: render.options.height / this.zoomScale,
          },
        );

        if (this.player.position.y > 3000) {
          this.manager.loadLevel();
        }

        const force = 1;
        if (
          this.keys[38] &&
          (this.released[38] && Date.now() - this.released[38] > 100) &&
          this.player &&
          (this.player as any).jumps > 0 &&
          (this.player as any).canJump &&
          !(this.player as any).canJumpCooldown
        ) {
          const angles = [];
          this.playerCollidingPairs.forEach((pair: Pairs) => {
            if (!(pair as any).isSensor && (pair as any).activeContacts.length > 0) {
              var normalPosX = (pair as any).activeContacts[0].vertex.x,
                normalPosY = (pair as any).activeContacts[0].vertex.y;

              if ((pair as any).activeContacts.length === 2) {
                normalPosX = ((pair as any).activeContacts[0].vertex.x + (pair as any).activeContacts[1].vertex.x) / 2;
                normalPosY = ((pair as any).activeContacts[0].vertex.y + (pair as any).activeContacts[1].vertex.y) / 2;
              }

              const angle = Math.atan2(normalPosY - this.player.position.y, normalPosX - this.player.position.x);
              const normalizedAngle = Math.atan2(Math.sin(angle), Math.cos(angle));

              angles.push(normalizedAngle);
            }
          });

          // NOTE THATT THE Y AXIS IS INVERTED
          const averageAngle = angles.reduce((p, c) => p + c, 0) / angles.length;
          const invertedAngle = Math.atan2(Math.sin(averageAngle + Math.PI), Math.cos(averageAngle + Math.PI));

          let jumpDir = 0;
          let jump = Vector.create(0, 0);

          // https://github.com/liabru/matter-js/issues/767
          // calculating jump height
          const deltaSquared = Math.pow(runner.delta * engine.timing.timeScale * this.player.timeScale, 2);
          // 1000 = 1 sec
          const correction = deltaSquared / 1000;

          if (averageAngle > 0) {
            // standing on something
            jumpDir = [invertedAngle + Math.PI, -Math.PI / 2 + Math.PI].reduce((p, c) => p + c, 0) / 2 - Math.PI / 2;
            jump = Vector.create(this.player.velocity.x, -Math.sqrt(2 * engine.world.gravity.y * 150 * correction));
          } else if (averageAngle > 0 - Math.PI / 8) {
            // slightly overhang from top right
            const newAverage = [invertedAngle, -Math.PI / 2].reduce((p, c) => p + c, 0) / 2;
            const newInverted = Math.atan2(Math.sin(newAverage + Math.PI), Math.cos(newAverage + Math.PI));

            jumpDir = newInverted + Math.PI / 2;
            jump = Vector.create(0, -Math.sqrt(2 * engine.world.gravity.y * 150 * correction));
          } else if (averageAngle < -Math.PI + Math.PI / 8) {
            // slightly overhang from top left
            jumpDir = [invertedAngle + Math.PI, -Math.PI / 2 + Math.PI].reduce((p, c) => p + c, 0) / 2 - Math.PI / 2;
            jump = Vector.create(0, -Math.sqrt(2 * engine.world.gravity.y * 150 * correction));
          } else {
            if (Number.isNaN(averageAngle)) {
              // in air jump
              jump = Vector.create(this.player.velocity.x, -Math.sqrt(2 * engine.world.gravity.y * 150 * correction));
            }
          }

          Body.setVelocity(this.player, Vector.rotate(jump, jumpDir));

          this.released[38] = null;
          (this.player as any).jumps--;
          (this.player as any).canJump = false;
          (this.player as any).canJumpCooldown = true;

          setTimeout(() => {
            (this.player as any).canJumpCooldown = false;
          }, 200);
        }

        if (this.keys[40]) {
          Body.applyForce(this.player, this.player.position, {
            x: 0,
            y: force,
          });
        }

        if (this.keys[37]) {
          Body.applyForce(this.player, this.player.position, {
            x: -force,
            y: 0,
          });
        } else if (this.keys[39]) {
          Body.applyForce(this.player, this.player.position, {
            x: force,
            y: 0,
          });
        }

        const max = 10;

        const velocity = { ...this.player.velocity };

        if (Math.abs(this.player.velocity.x) > Math.abs(this.player.velocity.y)) {
          if (Math.abs(this.player.velocity.x) > max) {
            const scale = this.player.velocity.x > 0 ? 1 : -1;
            velocity.x = max * scale;
          }
        } else {
          if (Math.abs(this.player.velocity.y) > max) {
            const scale = this.player.velocity.y > 0 ? 1 : -1;
            velocity.y = max * scale;
          }
        }

        Body.setVelocity(this.player, velocity);
      }
    });

    // keep the mouse in sync with rendering
    (render as any).mouse = this.mouse;

    runner.isFixed = true;

    Render.run(render);
    this.update();

    // making sure keydown event listener works by setting tabindex
    this.container.focus();
    this.container.setAttribute('tabindex', '1');

    window.onkeydown = e => {
      this.keyDown(e.keyCode);
    };

    window.onkeyup = e => {
      this.keyUp(e.keyCode);
    };

    this.mouse.element.addEventListener('mousedown', e => {
      // https://stackoverflow.com/questions/55257829/how-to-implement-hover-behavior-in-matter-js

      if (!this.debug) return;
      const foundPhysics = Query.point(engine.world.bodies, this.mouse.position);
      if (foundPhysics.length > 0) {
        const body = foundPhysics[0];

        if (!this.selected || this.selected !== body) {
          if (this.selected !== body && this.initialSelected) {
            this.selected.render = { ...this.initialSelected.render };
          }
          this.initialSelected = { isStatic: body.isStatic, render: { ...body.render } } as any;
          this.selected = body;
          this.selected.isStatic = true;
          this.selected.render.fillStyle = 'red';
        }
      } else {
        if (this.selected && this.initialSelected) {
          this.selected.render = { ...this.initialSelected.render };
        }
        this.selected = null;
        this.initialSelected = null;
      }
    });

    this.mouse.element.addEventListener('mousemove', e => {
      if (!this.selected && this.debug && e.buttons === 1 && !this.running) {
        const camera = {
          x: (render.bounds.max.x + render.bounds.min.x) / 2,
          y: (render.bounds.max.y + render.bounds.min.y) / 2,
        };

        (Render as any).lookAt(
          render,
          {
            x: camera.x - e.movementX,
            y: camera.y - e.movementY,
          },
          {
            x: render.options.width / this.zoomScale,
            y: render.options.height / this.zoomScale,
          },
        );
      }
      if (this.selected && this.debug && e.buttons === 1) {
        Body.setPosition(this.selected, this.mouse.position);
      }
    });

    // render.canvas.onmouseup = e => {};

    this.container.onmouseup = e => {
      if (this.debug && this.selected) {
        if (!this.initialSelected.isStatic) {
          this.selected.isStatic = false;
        }
      }
    };

    this.container.oncontextmenu = e => {
      return false;
    };

    this.mouse.element.onwheel = e => {
      e.preventDefault();

      if (!this.debug) return;
      if (this.selected) {
        // rotated scale doesnt really work well so we rotate it to an angle of 0 radians and scale. revert angle
        const { angle: initialAngle } = this.selected;
        Body.setAngle(this.selected, 0);
        let scaleY = 1;
        let scaleX = e.deltaY < 0 ? 1.05 : 0.95;

        if (this.keys[16]) {
          scaleY = e.deltaY < 0 ? 1.05 : 0.95;
          scaleX = 1;
        }

        if (this.keys[17]) {
          scaleX = e.deltaY < 0 ? 1.05 : 0.95;
          scaleY = e.deltaY < 0 ? 1.05 : 0.95;
        }

        const entity = this.entities.find(entity => entity.body === this.selected);
        entity.width *= scaleX;
        entity.height *= scaleY;
        Body.scale(this.selected, scaleX, scaleY);
        Body.setAngle(this.selected, initialAngle);
      } else {
        this.zoomScale = e.deltaY < 0 ? (this.zoomScale *= 1.05) : this.zoomScale * 0.95;
        if (this.player) {
          (Render as any).lookAt(
            render,
            {
              x: this.player.position.x,
              y: this.player.position.y,
            },
            {
              x: render.options.width / this.zoomScale,
              y: render.options.height / this.zoomScale,
            },
          );
        }
      }
    };

    this.mouse.element.addEventListener('mouseup', e => {
      // // Body.setStatic(this.selected, false);
      // if (this.selected) {
      //   // not doing this
      //   // this.selected.isStatic = false;
      //   this.selected = null;
      // }
    });

    window.addEventListener('resize', e => {
      this.resize();
    });
  }

  public keyUp(keyCode: number) {
    this.keys[keyCode] = false;
    this.released[keyCode] = Date.now();
  }

  public keyDown(keyCode: number) {
    this.keys[keyCode] = true;
    this.controls(keyCode);
  }

  public die() {
    // create random particles based on user velocity, ...
    const player = this.player;
    World.remove(engine.world, player);
    for (let i = 0; i < 5; i++) {
      const particle = Bodies.circle(this.player.position.x, this.player.position.y, Math.random() * 18 + 2, {
        label: 'Particle',
        friction: 0,
        frictionAir: 0.01,
        frictionStatic: 0.01,
        // force: player.force,
        inertia: Infinity,
        render: {
          strokeStyle: 'red',
          fillStyle: 'red',
        },
      });
      Body.setVelocity(particle, player.velocity);
      World.add(engine.world, particle);
    }

    // this.manager.fade();

    // if (this.resetTimeout === 0) {
    this.resetTimeout = setTimeout(() => {
      this.manager.loadLevel(this.manager.levelIndex);
      // this.manager.fade();
    }, 1000);
    // }

    // remove player
    // reset
    // maybe set reset to i nterval, clear interval on R key
    // reset pause and clear pause interval
  }

  public update = (time = 0) => {
    requestAnimationFrame(this.update);

    this.now = Date.now();
    this.delta = this.now - this.then;

    if (this.delta > this.interval && this.running) {
      Runner.tick(runner, engine, time);
      this.then = this.now - (this.delta % this.interval);
    }
  };

  public pause() {
    runner.enabled = false;
    this.running = false;
    if (this.player) this.player.isStatic = true;
  }

  public resume() {
    runner.enabled = true;
    this.running = true;
    if (this.player) this.player.isStatic = false;
  }

  public resize() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;

    render.canvas.width = this.container.clientWidth;
    render.canvas.height = this.container.clientHeight;
    render.options.width = this.container.clientWidth;
    render.options.height = this.container.clientHeight;
  }

  public reset() {
    this.levelFinished = false;
    clearInterval(this.resetTimeout);
    this.resetTimeout = 0;
    const spawner: Spawner = this.entities.find(entity => entity instanceof Spawner);

    if (spawner) {
      const [x, y] = [spawner.body.position.x, spawner.body.position.y];

      this.player = Bodies.circle(x, y, 25, {
        label: 'player',
        // density: 1,
        friction: 0,
        // friction: 0.01,
        // frictionAir: 0.01,
        frictionAir: 0.0,

        frictionStatic: 0.0,
        // frictionStatic: 0.01,
        inertia: Infinity,
        render: {
          // strokeStyle: 'black',
          fillStyle: 'orange ',
        },
      });

      Body.setMass(this.player, 1000);

      (this.player as any).jumps = 0;
      // 1;
      World.add(engine.world, [this.player]);

      (Render as any).lookAt(
        render,
        {
          x: this.player.position.x,
          y: this.player.position.y,
        },
        {
          x: render.options.width / this.zoomScale,
          y: render.options.height / this.zoomScale,
        },
      );
    }
  }

  public clear = () => {
    this.entities = [];
    this.player = null;
    World.clear(engine.world, false);
  };

  public controls(keyCode) {
    switch (keyCode) {
      case 189:
        if (this.debug && this.selected) {
          const platform = this.entities.find(entity => entity.body === this.selected);
          platform.moveOverX -= 10;
        }

        break;
      case 187:
        if (this.debug && this.selected) {
          const platform = this.entities.find(entity => entity.body === this.selected);
          platform.moveOverX += 10;
        }

        break;
      case 219:
        if (this.debug && this.selected) {
          const platform = this.entities.find(entity => entity.body === this.selected);
          platform.moveOverY -= 10;
        }

        break;
      case 221:
        if (this.debug && this.selected) {
          const platform = this.entities.find(entity => entity.body === this.selected);
          platform.moveOverY += 10;
        }

        break;
      case 186:
        if (this.debug && this.selected) {
          const platform = this.entities.find(entity => entity.body === this.selected);
          platform.moveOverX = 0;
        }

        break;
      case 222:
        if (this.debug && this.selected) {
          const platform = this.entities.find(entity => entity.body === this.selected);
          platform.moveOverY = 0;
        }

        break;
      case 80:
        this.running ? this.pause() : this.resume();
        break;
      case 82:
        this.manager.loadLevel(this.manager.levelIndex);
        break;

      case 81:
        if (this.debug && this.selected) {
          const angle = -Math.PI / 20;
          const entity = this.entities.find(entity => entity.body === this.selected);
          entity.angle += angle;
          Body.rotate(this.selected, angle);
        }
        break;
      case 69:
        if (this.debug && this.selected) {
          const angle = Math.PI / 20;
          const entity = this.entities.find(entity => entity.body === this.selected);
          entity.angle += angle;
          Body.rotate(this.selected, angle);
        }
        break;
      case 70:
        // console.log(this.selected);
        if (this.selected) {
          const entity = this.entities.find(entity => entity.body === this.selected);
          const newEntity = new entity.constructor({
            ...this.mouse.position,
            ...(entity.length ? { length: entity.length } : {}),
            ...(entity.width ? { width: entity.width } : {}),
            ...(entity.height ? { height: entity.height } : {}),
            ...(entity.radius ? { radius: entity.radius } : {}),
            ...(entity.angle ? { angle: entity.angle } : {}),
          });

          this.entities.push(newEntity);
        } else {
          console.log('Nothing selected');
        }
        break;
      case 46:
        if (this.debug) {
          World.remove(engine.world, this.selected);
          this.entities.splice(this.entities.map(entity => entity.body).indexOf(this.selected), 1);
        }
        break;
      case 57:
        this.debug && this.entities.push(new Checkpoint({ ...this.mouse.position }));
        break;
      case 48:
        if (this.debug) {
          if (this.entities.some(entity => entity instanceof Spawner)) {
            throw new Error('THERE IS ALREADY A SPAWNER');
          }

          this.entities.push(new Spawner({ ...this.mouse.position }));
        }
        break;
      case 49:
        if (this.debug) {
          const wall = new Platform({ ...this.mouse.position, width: 500, height: 50 } as any);
          this.entities.push(wall);
          this.initialSelected = { isStatic: wall.body.isStatic, render: { ...wall.body.render } } as any;
          this.selected = wall.body;
          this.selected.isStatic = true;
          this.selected.render.fillStyle = 'red';
        }
        break;
      case 50:
        this.debug && this.entities.push(new Saw({ ...this.mouse.position, radius: 60 }));
        break;
      case 51:
        this.debug && this.entities.push(new Spike({ ...this.mouse.position, length: 20 }));
        break;
      case 32:
        if (this.debug) {
          (Render as any).lookAt(
            render,
            {
              x: this.player.position.x,
              y: this.player.position.y,
            },
            {
              x: render.options.width / this.zoomScale,
              y: render.options.height / this.zoomScale,
            },
          );
        }
        break;
    }
  }
}
