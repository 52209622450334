import { Bodies, Body, World } from 'matter-js';

import { engine } from './game';

export default class Checkpoint {
  public body: Body;
  public x;
  public y;

  constructor(opts: { x; y }) {
    this.body = Bodies.rectangle(opts.x, opts.y, 50, 100, { isStatic: true, isSensor: true, label: 'Checkpoint', render: { fillStyle: 'green' } });
    this.x = opts.x;
    this.y = opts.y;
    World.add(engine.world, this.body);
  }
}
