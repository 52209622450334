import { Bodies, Body, World } from 'matter-js';

import { engine } from './game';

export default class Platform {
  // public body: Body;\
  //compount of all bodies
  public body: Body;
  public moveOverX = 0;
  public moveOverY = 0;

  public initialX = 0;
  public initialY = 0;

  public width = 500;
  public height = 50;

  public angle = 0;

  public offset = Math.random() * 1000;

  constructor(opts: { x; y; width; height; moveOverX; moveOverY; angle }) {
    this.body = Bodies.rectangle(opts.x, opts.y, opts.width, opts.height, { angle: opts.angle ? opts.angle : 0, isStatic: true, friction: 0, label: 'Platform', render: { fillStyle: 'black' } });

    this.width = opts.width;
    this.height = opts.height;
    this.angle = opts.angle ? opts.angle : 0;

    // this.moveOverX = opts.moveOverX ? opts.moveOverX : 0;
    // this.moveOverY = opts.moveOverY ? opts.moveOverY : 0;
    // this.initialX = opts.x;
    // this.initialY = opts.y;

    World.add(engine.world, this.body);
  }

  public update() {
    if (this.moveOverX !== 0 || this.moveOverY !== 0) {
      Body.setPosition(this.body, {
        x: this.initialX + Math.cos(engine.timing.timestamp / 1000 + this.offset) * this.moveOverX,
        y: this.initialY + Math.sin(engine.timing.timestamp / 1000 + this.offset) * this.moveOverY,
      });
    }
    // Body.rotate(this.body, Math.PI / 50);
    // this.body.angle += Math.PI / 20;
  }
}
