import './styles/index.module.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { connect, Provider } from 'react-redux';

import FastClick from 'fastclick';

import Manager from './manager';
import store from './store';

import * as notify from 'pling';

export default class App extends React.Component<any, any> {
  public manager: Manager;

  public canvas: React.RefObject<HTMLCanvasElement>;
  public container: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: true,
      levelsOpen: false,
      mobileControls: window.innerWidth < 600,
      settingsOpen: false,
      mainOpen: true,
      fadeOpen: true,
      debug: process.env.mode === 'development',
      touchingLeft: false,
      touchingUp: false,
      touchingRight: false,
      touchingDown: false,
      touchingMenu: false,
      touchingReset: false,
      width: 0,
      height: 0,
    };

    this.canvas = React.createRef();
    this.container = React.createRef();

    window.addEventListener('keydown', e => {
      if (e.keyCode === 27) {
        this.toggleMenu();
      }
    });

    window.addEventListener(
      'DOMContentLoaded',
      function() {
        FastClick.attach(document.body);
      },
      false,
    );

    window.addEventListener('resize', this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  public componentDidMount() {
    this.manager = new Manager({
      state: this.state,
      container: this.container.current,
      canvas: this.canvas.current,
      debug: this.state.debug,
      toggleFade: this.toggleFade,
      toggleMenu: this.toggleMenu,
      toggleDebug: this.toggleDebug,
    });

    // this enables the debug mode to instantly play
    if (process.env.mode === 'development') {
      this.play();
    }

    this.updateWindowDimensions();

    // ugly fix to mount dom to manager
    // this.forceUpdate();
  }

  public componentDidUpdate() {}

  public toggleMenu = () => {
    if (!this.state.menuOpen) {
      this.setState({ menuOpen: true });
      this.setState({ fadeOpen: true });

      setTimeout(() => this.setState({ mainOpen: true }), 500);
    } else {
      this.play();
    }
  };

  public toggleFade = () => {
    this.setState({ fadeOpen: !this.state.fadeOpen });
  };

  public play = () => {
    this.setState({ fadeOpen: false });

    this.setState({ mainOpen: false });
    this.setState({ levelsOpen: false });

    // hide the whole thing
    setTimeout(() => this.setState({ menuOpen: false }), 500);

    this.manager.play();
  };

  public showMain = () => {
    this.setState({ settingsOpen: false, levelsOpen: false, mainOpen: true });
  };

  public showLevels = () => {
    if (this.state.levelsOpen) {
      this.setState({ settingsOpen: false, levelsOpen: false, mainOpen: true });
    } else {
      this.setState({ settingsOpen: false, levelsOpen: true, mainOpen: false });
    }
  };

  public showSettings = () => {
    if (this.state.settingsOpen) {
      this.setState({ settingsOpen: false, levelsOpen: false, mainOpen: true });
    } else {
      this.setState({ settingsOpen: true, levelsOpen: false, mainOpen: false });
    }
  };

  public loadLevel = (level: number) => {
    this.manager.loadLevel(level);
    this.play();
  };

  public toggleDebug = () => {
    this.setState({ debug: !this.state.debug });
  };

  public toggleMobileControls = () => {
    this.setState({ mobileControls: !this.state.mobileControls });
  };

  public render = () => {
    const level = (this.manager && this.manager.levelIndex) || 0;
    const lastLevelIndex = (this.manager && this.manager.profile.lastLevelIndex) || 0;
    // const levels = localStorage.getItem('levels') ? JSON.parse(localStorage.getItem('levels')) : [];
    const levels = this.manager && this.manager.levels ? this.manager.levels : [];
    return (
      <>
        <div className={'toggle'}>
          <div
            className={`ietske ${this.state.touchingMenu && 'ietskeHover'}`}
            onClick={() => {
              this.toggleMenu();
              // this.setState({ touchingMenu: true });
            }}
            onTouchStart={() => {
              this.toggleMenu();
              this.setState({ touchingMenu: true });
            }}
            onTouchEnd={() => {
              this.setState({ touchingMenu: false });
            }}
            // onTouchEnd={() => {
            //   // this.toggleMenu();
            //   this.setState({ touchingMenu: false });
            // }}
          >
            ↩️
          </div>
        </div>
        {this.state.mobileControls && (
          <div className={'touch'}>
            <div className={'navigation'}>
              <div
                className={`button ${this.state.touchingRestart && 'buttonPress'}`}
                onTouchStart={() => {
                  this.manager.game.keyDown(82);
                  this.setState({ touchingRestart: true });
                }}
                onTouchEnd={() => {
                  this.manager.game.keyUp(82);
                  this.setState({ touchingRestart: false });
                }}
              >
                🔄
              </div>
            </div>
            <div className={'controls'}>
              <div className={'row'}>
                <div
                  className={`button buttonControl ${this.state.touchingUp && 'buttonPress'}`}
                  onTouchStart={() => {
                    this.manager.game.keyDown(38);
                    this.setState({ touchingUp: true });
                  }}
                  onTouchEnd={() => {
                    this.manager.game.keyUp(38);
                    this.setState({ touchingUp: false });
                  }}
                >
                  🔼
                </div>
              </div>
              <div className={'row'}>
                <div
                  className={`button buttonControl ${this.state.touchingLeft && 'buttonPress'}`}
                  onTouchStart={() => {
                    this.manager.game.keyDown(37);
                    this.setState({ touchingLeft: true });
                  }}
                  onTouchEnd={() => {
                    this.manager.game.keyUp(37);
                    this.setState({ touchingLeft: false });
                  }}
                >
                  ◀️
                </div>
                <div
                  className={`button buttonControl ${this.state.touchingRight && 'buttonPress'}`}
                  onTouchStart={() => {
                    this.manager.game.keyDown(39);
                    this.setState({ touchingRight: true });
                  }}
                  onTouchEnd={() => {
                    this.manager.game.keyUp(39);
                    this.setState({ touchingRight: false });
                  }}
                >
                  ▶️
                </div>
              </div>
              <div className={'row'}>
                <div
                  className={`button buttonControl ${this.state.touchingDown && 'buttonPress'}`}
                  onTouchStart={() => {
                    this.manager.game.keyDown(40);
                    this.setState({ touchingDown: true });
                  }}
                  onTouchEnd={() => {
                    this.manager.game.keyUp(40);
                    this.setState({ touchingDown: false });
                  }}
                >
                  🔽
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={'app'} ref={this.container}></div>
        {!this.state.debug && (
          <>
            <div className={`fade fade1 ${!this.state.fadeOpen ? 'fade1Hidden' : ''}`}></div>
            <div className={`fade fade2 ${!this.state.fadeOpen ? 'fade2Hidden' : ''}`}></div>
            <div className={`fade fade3 ${!this.state.fadeOpen ? 'fade3Hidden' : ''}`}></div>
            <div className={`fade fade4 ${!this.state.fadeOpen ? 'fade4Hidden' : ''}`}></div>
            {/* {this.state.menuOpen && ( */}
            <div className={`overlay  ${this.state.menuOpen ? '' : 'menuHidden'} `}>
              <div className={`menu main ${this.state.mainOpen ? '' : 'mainHidden'}`}>
                <h1>Reutemeteut</h1>
                <div className={'logo-corner'}>
                  {/* <img className={'game-logo'} src={'/assets/logo.png'} />
              <p>Alpha Version: v2.0.0.0</p> */}
                  {/* check if build number or just v1 */}
                </div>

                <div className={'button-menu'}>
                  <h2 className={'menu-button'} onClick={() => this.play()}>
                    {level === 0 ? (lastLevelIndex === levels.length - 1 ? 'Replay' : 'Play') : 'Resume'}
                  </h2>

                  <h2 className={'menu-button'} onClick={() => this.showLevels()}>
                    Levels
                  </h2>

                  <h2 className={'menu-button'} onClick={() => this.toggleMobileControls()}>
                    {this.state.mobileControls ? 'Disable mobile controls' : 'Enable mobile controls'}
                  </h2>

                  {/* <h2 className={'menu-button'} onClick={() => this.showSettings()}>
                    Settings
                  </h2> */}
                  {/* <h2 className={'menu-button'} style={{ textDecorationLine: 'line-through' }}>
                  Highscores
                </h2>
                <h2 className={'menu-button'}>Settings</h2> */}
                  <div className={'info'}>
                    <h2>Controls</h2>
                    <span>Movement: Arrow keys</span>
                    <span>Restart level: R</span>
                    <span>Toggle Menu: Escape key</span>
                  </div>
                </div>
              </div>
              <div className={`menu levelSelection ${this.state.levelsOpen ? '' : 'hidden'}`}>
                <div className={'button-menu'}>
                  <h2 className={'menu-button'} onClick={() => this.showMain()}>
                    {'<'} Levels
                  </h2>
                  <div className={'levels'}>
                    {levels.map((_, i) => (
                      <div
                        className={`level ${lastLevelIndex >= i ? 'levelHover' : ''} ${i <= lastLevelIndex ? 'levelDone' : 'levelBlocked'} ${i === level ? 'levelCurrent' : ''}`}
                        onClick={() => {
                          if (lastLevelIndex >= i) {
                            this.loadLevel(i);
                          }
                        }}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className={`menu settings ${this.state.settingsOpen ? '' : 'hidden'}`}>
                <div className={'button-menu'}>
                  <h2 className={'menu-button'} onClick={() => this.showMain()}>
                    {'<'} Settings
                  </h2>
                  <div className={'settings'}>
                  <form action="">
				<div class="form-group">
					<label for="settingsInputUsername">Username</label>
					<input type="text" class="form-control" id="settingsInputUsername" maxlength="12" placeholder="Some Duck">
				</div>
				<p class="saved-warning" style="display:none;">Settings saved.</p>
				<button type="submit" class="btn btn-primary w-100" id="saveSettingsBtn">Save</button>
			</form>
                  </div>
                </div>
              </div> */}
            </div>
            <div className={`footer  ${this.state.menuOpen ? '' : 'footerGame'}`}>
              <span>
                Made for <a href={'https://ldjam.com/events/ludum-dare/45/ld45-submission'}>Ludum Dare 45</a>, by <a href={'https://twitter.com/croewens'}>@CROEWENS</a>
              </span>
            </div>
          </>
        )}
      </>
    );
  };
}

const mapDispatchToProps = {};

const Reutemeteut = connect(
  null,
  mapDispatchToProps,
)(App);

ReactDOM.render(
  <Provider store={store}>
    <Reutemeteut />
  </Provider>,
  document.getElementById('root'),
);
