import { engine } from './game';
import { World, Body, Bodies } from 'matter-js';

export default class Saw {
  // public body: Body;\
  //compount of all bodies
  public body: Body;
  public bodies: Body[];
  public radius;
  constructor(opts: { x; y; radius }) {
    this.radius = opts.radius;
    this.bodies = [
      Bodies.polygon(opts.x, opts.y, 3, opts.radius, {
        isStatic: true,
        angle: 0,
        render: {
          fillStyle: 'grey',
        },
      }),
      Bodies.polygon(opts.x, opts.y, 3, opts.radius, {
        isStatic: true,
        angle: Math.PI / 2,
        render: {
          fillStyle: 'grey',
        },
      }),
      Bodies.polygon(opts.x, opts.y, 3, opts.radius, {
        isStatic: true,
        angle: Math.PI,
        render: {
          fillStyle: 'grey',
        },
      }),
      Bodies.polygon(opts.x, opts.y, 3, opts.radius, {
        isStatic: true,
        angle: (Math.PI / 2) * 3,
        render: {
          fillStyle: 'grey',
        },
      }),
    ];

    this.body = Body.create({
      parts: this.bodies,
      isStatic: true,
      label: 'Saw',
    });

    World.add(engine.world, this.body);
  }

  public update() {
    Body.rotate(this.body, Math.PI / 50);
    // this.body.angle += Math.PI / 20;
  }
}
