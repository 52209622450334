import { Bodies, Body, World } from 'matter-js';

import { engine } from './game';

export default class Spawner {
  public body: Body;

  constructor(opts: { x; y }) {
    this.body = Bodies.rectangle(opts.x, opts.y, 50, 10, { isStatic: true, isSensor: true, render: { fillStyle: 'lightblue' } });
    World.add(engine.world, this.body);
  }
}
