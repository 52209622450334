export const level0 = [
  { x: -264.48244067492146, y: 984.7675265374667, width: 700.0368574603062, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -501.6750458115366, y: 954.2363603568847, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -122.25707149829265, y: 1884.087672025184, width: 281.5549377034132, height: 50.00000000000091, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -62, y: 1229.050000277779, width: 663.372427050822, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -421.34912938518164, y: 1897.703423989845, width: 338.8364271301332, height: 19.860715922910913, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 123.67058315779377, y: 1450.236360357021, width: 406.2180321624712, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 561.6705831577938, y: 1345.236360357021, width: 281.5549377034122, height: 50, angle: -1.413716694115407, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 474.0412534028509, y: 1603.4766956011165, width: 281.5549377034124, height: 50, angle: -1.0995574287564276, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 318.70303431095635, y: 1783.8694661594454, width: 281.5549377034124, height: 50, angle: -0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 119.93692601057512, y: 1862.3737274209407, width: 281.5549377034124, height: 50, angle: -0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -444.3249210283108, y: 1451.7518407035864, width: 340.53698369245024, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -173.67030343109593, y: 1451.6100068908563, width: 119.99999999999767, height: 119.99999999999864, angle: 37.76194369614945, type: 'Saw', radius: 60 },
  { x: -640.3007126714405, y: 1079.974822109918, width: 790.347069526486, height: 50.00000000000068, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 293.5470797556841, y: 1007.9162148693059, width: 486.42059236031025, height: 50.00000000000023, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -759.8486151060831, y: 1882.7797960428534, width: 340.53698369245035, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -289.39515832862617, y: 1877.6597828166973, width: 30.000000000000114, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -564.9952244594067, y: 1875.9894793856017, width: 29.999999999999886, height: 34.641999999999825, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -531.5891558374939, y: 1875.9894793856017, width: 30, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -496.51278378448546, y: 1877.6597828166973, width: 30.000000000000057, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -461.43641173147705, y: 1875.9894793856017, width: 30.000000000000114, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -426.36003967846864, y: 1877.6597828166973, width: 30.000000000000114, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -394.6242744876514, y: 1877.6597828166973, width: 30.000000000000114, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -359.547902434643, y: 1877.6597828166973, width: 30.000000000000114, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -326.1418338127303, y: 1877.6597828166973, width: 30.000000000000114, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -845.6873484770763, y: 1806.2755347813584, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: -954.9273749293884, y: 1704.3870254845242, width: 407.2531250000002, height: 50.00000000000182, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -919.1806994452844, y: 1838.0112999721755, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -919.1806994452844, y: 1771.19916272835, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -917.5103960141887, y: 1804.6052313502628, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: 261.5470797556841, y: 783.9162148693059, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: 255.54707975568408, y: 859.9162148693059, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: 259.5470797556841, y: 819.9162148693059, width: 30, height: 34.64200000000005, angle: 0.15707963267948966, type: 'Spike', length: 20 },
  { x: 257.5470797556841, y: 1183.9162148693058, width: 30, height: 34.641999999999825, angle: 0, type: 'Spike', length: 20 },
  { x: 259.5470797556841, y: 895.9162148693059, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: 259.5470797556841, y: 929.9162148693059, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: 255.54707975568408, y: 965.9162148693059, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: 257.5470797556841, y: 1001.9162148693059, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: 255.54707975568408, y: 1035.9162148693058, width: 30, height: 34.64199999999994, angle: 0, type: 'Spike', length: 20 },
  { x: 257.5470797556841, y: 1075.9162148693058, width: 30, height: 34.641999999999825, angle: 0, type: 'Spike', length: 20 },
  { x: 257.5470797556841, y: 1111.9162148693058, width: 30, height: 34.641999999999825, angle: 0, type: 'Spike', length: 20 },
  { x: 255.54707975568408, y: 1147.9162148693058, width: 30, height: 34.641999999999825, angle: 0, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1389.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -606.6750458115366, y: 721.2363603568847, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1069.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1105.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1141.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1179.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -606.4529202443159, y: 1215.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -606.4529202443159, y: 1253.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -606.4529202443159, y: 1291.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -606.4529202443159, y: 1323.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1359.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1389.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: 443.67058315779377, y: 1205.236360357021, width: 120.00000000000034, height: 119.99999999999909, angle: 37.76194369614945, type: 'Saw', radius: 60 },
  { x: 336.3249541884634, y: 1449.2363603568847, width: 30.00000000000051, height: 34.641999999999825, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: 264, y: 1415.8175268152454, width: 30, height: 34.641999999999825, angle: 3.612831551628262, type: 'Spike', length: 20 },
  { x: 230, y: 1415.8175268152454, width: 29.99999999999997, height: 34.641999999999825, angle: 3.612831551628262, type: 'Spike', length: 20 },
  { x: 300.67058315779377, y: 1416.236360357021, width: 30.000000000000114, height: 34.641999999999825, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -604.4529202443159, y: 1033.9162148693058, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -605.6750458115366, y: 938.2363603568847, width: 30, height: 34.64200000000005, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -607.6750458115366, y: 900.2363603568847, width: 30, height: 34.64200000000005, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -607.6750458115366, y: 854.2363603568847, width: 30, height: 34.64200000000005, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -608.6750458115366, y: 813.2363603568847, width: 30, height: 34.64200000000005, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -608.6750458115366, y: 765.2363603568847, width: 30, height: 34.64200000000005, angle: 3.141592653589793, type: 'Spike', length: 20 },
];

export const level1 = [
  { x: -4197.393493169108, y: 909.2212226943113, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -1764.4050030033823, y: 659.3793852938998, width: 119.99999999998363, height: 119.99999999998477, angle: 222.73891913952588, type: 'Saw', radius: 60.00000000000023 },
  { x: -1080.9729406246706, y: 852.7850793337323, width: 119.99999999999329, height: 119.99999999999272, angle: 222.73891913952588, type: 'Saw', radius: 60.00000000000023 },
  { x: -343.39645081951494, y: 1030.6787549518076, width: 119.99999999999704, height: 119.99999999998704, angle: 222.73891913952588, type: 'Saw', radius: 60.000000000000256 },
  { x: 703.2852149135126, y: 958.0785796440384, width: 38.689046875000145, height: 99.99999999999989, angle: 0, type: 'Checkpoint' },
  { x: -2321.89912307329, y: 996.2306969620587, width: 507.54487444729784, height: 49.99999999999932, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1484.72470365472, y: 928.0003744631504, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1153.7421537289238, y: 935.0425563764652, width: 119.99999999998363, height: 119.99999999998477, angle: 222.73891913952588, type: 'Saw', radius: 60.00000000000023 },
  { x: -1024.635485318152, y: 930.3477684342553, width: 119.99999999999852, height: 119.99999999999147, angle: 222.73891913952588, type: 'Saw', radius: 59.99999999999966 },
  { x: -693.6529353923561, y: 761.2367144606396, width: 407.25312500000024, height: 50.000000000000455, angle: 0.47123889803846897, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -872.0548771963313, y: 737.7627747495902, width: 119.99999999999068, height: 119.99999999998863, angle: 222.73891913952588, type: 'Saw', radius: 59.999999999999545 },
  { x: -775.8117243810289, y: 791.7528360850038, width: 119.99999999999477, height: 119.99999999999477, angle: 222.73891913952588, type: 'Saw', radius: 59.999999999999545 },
  { x: -677.2211775946216, y: 834.0059275648928, width: 119.99999999999477, height: 119.99999999999477, angle: 222.73891913952588, type: 'Saw', radius: 59.999999999999545 },
  { x: -583.3254187504235, y: 890.3433828714112, width: 119.99999999999477, height: 119.99999999999477, angle: 222.73891913952588, type: 'Saw', radius: 59.999999999999545 },
  { x: -4003.478434650319, y: 941.9860502357199, width: 499.99999999999363, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3666.882455699392, y: 937.3899503475706, width: 119.99999999998727, height: 119.99999999997817, angle: 222.73891913952588, type: 'Saw', radius: 59.999999999999545 },
  { x: -3491.4050030033823, y: 935.3793852938998, width: 161.7667724868561, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2536.0304682663823, y: 856.466478780088, width: 284.4000461382302, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1622.4380134535677, y: 612.9713171076658, width: 428.6875000000023, height: 50.000000000000455, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2061.9115259126875, y: 652.6151671119269, width: 407.2531250000002, height: 49.99999999999977, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2094.5738851406313, y: 849.3793852938998, width: 342.24607502963045, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2705.4050030033823, y: 735.3793852938998, width: 472.62796875000186, height: 50.00000000000091, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 107.83536182207354, y: 850.8686676448516, width: 1257.1656189187415, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -47.39645081951494, y: 880.6787549518076, width: 119.9999999999882, height: 119.99999999999227, angle: 222.73891913952588, type: 'Saw', radius: 59.99999999999942 },
  { x: 256.60354918048506, y: 1038.6787549518076, width: 119.99999999999028, height: 119.99999999998681, angle: 222.73891913952588, type: 'Saw', radius: 59.99999999999956 },
  { x: -2516.5738851406313, y: 1067.3793852938998, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2550.5738851406313, y: 1069.3793852938998, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2132.4050030033823, y: 955.3793852938998, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2132.4050030033823, y: 921.3793852938998, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2134.4050030033823, y: 887.3793852938998, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2132.4050030033823, y: 847.3793852938998, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2132.4050030033823, y: 807.3793852938998, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2132.4050030033823, y: 773.3793852938998, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2132.4050030033823, y: 725.3793852938996, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2670.4050030033823, y: 575.3793852938996, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2670.4050030033823, y: 805.3793852938998, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2674.4050030033823, y: 767.3793852938998, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2672.4050030033823, y: 731.3793852938996, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2672.4050030033823, y: 693.3793852938996, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2672.4050030033823, y: 651.3793852938996, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2672.4050030033823, y: 609.3793852938996, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -2691.4050030033823, y: 1095.3793852938998, width: 112.96777049628508, height: 50.00000000000182, angle: 0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3359.4050030033823, y: 921.3793852938998, width: 198.60715922911095, height: 50.00000000000091, angle: -0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3085.4050030033823, y: 835.3793852938998, width: 161.36235555563962, height: 50.00000000000182, angle: -0.47123889803846897, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3243.4050030033823, y: 899.3793852938998, width: 198.60715922910913, height: 50.000000000000455, angle: -0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2911.4050030033823, y: 863.3793852938998, width: 179.2429612042706, height: 50.000000000000455, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2875.4050030033823, y: 959.3793852938998, width: 112.96777049628236, height: 50.00000000000068, angle: 1.0995574287564276, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2837.4050030033823, y: 1023.3793852938998, width: 112.96777049628236, height: 50, angle: 0.7853981633974483, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2725.577473286311, y: 1102.3251870355123, width: 727.7158313700411, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2759.4050030033823, y: 1077.3793852938998, width: 112.96777049628236, height: 50, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2999.4050030033823, y: 791.3793852938998, width: 170.2808131440579, height: 50.00000000000023, angle: -0.47123889803846897, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2916.4050030033823, y: 749.3793852938998, width: 30, height: 34.642000000000166, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -1776.4050030033823, y: 785.3793852938998, width: 362.97453526037316, height: 50.00000000000114, angle: 0.9424777960769379, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1654.4050030033823, y: 907.3793852938998, width: 138.69478656091724, height: 50.00000000000091, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1281.4050030033823, y: 890.3793852938998, width: 125.17204487122763, height: 50, angle: -0.7853981633974483, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1329.4050030033823, y: 908.3793852938998, width: 125.17204487122763, height: 50, angle: -0.47123889803846897, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2299.566558366835, y: 682.6151671119269, width: 125.17204487122854, height: 50.000000000001364, angle: -0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -681.3415068136627, y: 1030.841455809038, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -31.225864215860156, y: 1027.9775102469334, width: 428.6875, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 550.155084891426, y: 1033.7054013711431, width: 428.6875, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
];

export const level2 = [
  { x: -2115.8866319258004, y: 1133.339363749056, width: 841.3156635194928, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2473.5285480860302, y: 1104.0562964931587, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -2001.8866319258004, y: 927.339363749056, width: 1091.4372941909696, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2185.8866319258004, y: 1085.339363749056, width: 63.45688328144479, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1933.8866319258004, y: 975.339363749056, width: 57.99111065000329, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1727.8866319258004, y: 1083.339363749056, width: 57.991110650003066, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1360.1131889434628, y: 1314.4880520808947, width: 428.6875000000002, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1172.1131889434628, y: 1124.4880520808947, width: 346.55642464291395, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1031.8866319258004, y: 991.339363749056, width: 315.1247048623054, height: 50, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1262.5285480860302, y: 775.3905062063395, width: 425.4803749423827, height: 50.000000000000455, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -447.7514058501847, y: 856.6443139543766, width: 1203.3096168455431, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1388.2934577882618, y: 755.1416574142264, width: 407.25312500000064, height: 50.00000000000091, angle: -1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1329.2458387406427, y: 930.3797526523216, width: 120, height: 120.00000000000045, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999818 },
  { x: -1536.6921373035052, y: 1384.1605499365432, width: 120.00000000000045, height: 120.00000000000045, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999997954 },
  { x: -1618.5968992082671, y: 1376.5415023174955, width: 120.00000000000045, height: 120.00000000000045, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999997954 },
  { x: -1441.45404206541, y: 1386.0653118413052, width: 120.00000000000045, height: 120.00000000000045, angle: 7.5398223686154875, type: 'Saw', radius: 60.00000000000068 },
  { x: -1696.6921373035052, y: 1372.7319785079717, width: 120.00000000000045, height: 120.00000000000045, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999997954 },
  { x: -748.7037868025659, y: 858.5490758591385, width: 119.99999999999909, height: 119.99999999999955, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999807 },
  { x: -647.7514058501849, y: 862.3585996686622, width: 119.99999999999909, height: 119.99999999999909, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999997726 },
  { x: -480.13235823113723, y: 673.7871710972338, width: 119.9999999999992, height: 119.99999999999977, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999835 },
  { x: -384.8942629930418, y: 669.9776472877099, width: 119.99999999999955, height: 120.00000000000023, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999998124 },
  { x: -200.13235823113723, y: 852.8347901448527, width: 119.9999999999996, height: 120.00000000000011, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999835 },
  { x: 128.47145191396976, y: 657.3905062063395, width: 349.168648046875, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -173.52854808603024, y: 313.3905062141557, width: 154.6399138763486, height: 50.00000000000102, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 136.47145191396976, y: 437.3905062141557, width: 112.40363769236723, height: 50, angle: -1.413716694115407, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 214.47145191396976, y: 209.39050621415572, width: 138.6947865609167, height: 50.000000000000654, angle: -1.0995574287564276, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -411.52854808603024, y: 227.39050621415572, width: 197.12132631588975, height: 50.0000000000002, angle: 0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -749.5285480860302, y: 213.39050621415572, width: 500, height: 49.99999999999994, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -494.48012361795077, y: 3.0495540556762535, width: 1039.4640897056843, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -52.52854808603024, y: 5.3905062063395235, width: 120.00000000000003, height: 119.99999999999994, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999856 },
  { x: -162.52854808603024, y: -0.6094937936604765, width: 120.00000000000013, height: 119.99999999999994, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999859 },
  { x: -1265.9086950465223, y: -36.95044594432375, width: 475.00000000000045, height: 50.00000000000048, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1180.5285480860302, y: 225.39050621415572, width: 120, height: 120.00000000000045, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999943 },
  { x: -1066.5285480860302, y: 221.39050621415572, width: 119.9999999999992, height: 120.0000000000004, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999761 },
  { x: 310.1668442545051, y: -73.07180432089095, width: 500.0000000000007, height: 50.00000000000037, angle: 0.9424777960769379, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 233.47145191396976, y: 145.39050620633952, width: 473.8125000000002, height: 50.00000000000013, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 257.8241269458142, y: -112.84574141815659, width: 119.99999999999994, height: 120.00000000000026, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999835 },
  { x: 201.02671029170324, y: -210.849127017407, width: 120.00000000000003, height: 119.99999999999983, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999998366 },
  { x: 310.1668442545049, y: -28.20645385516758, width: 119.99999999999977, height: 120.00000000000021, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999825 },
  { x: 355.8275125450648, y: 56.43283370782143, width: 119.99999999999972, height: 119.99999999999997, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999892 },
  { x: 429.3300517445027, y: 111.79821851405876, width: 119.99999999999977, height: 120.00000000000006, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999886 },
  { x: 678.1739881187168, y: 731.0072656313741, width: 989.9657997197005, height: 50.000000000000455, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 411.78099494300636, y: 646.7671908940451, width: 769.8611587616124, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 409.78099494300636, y: 354.7671908940451, width: 119.99999999999943, height: 119.99999999999943, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999841 },
  { x: 415.78099494300636, y: 468.7671908940451, width: 120.00000000000017, height: 119.99999999999949, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999869 },
  { x: 670.1739881187168, y: 611.0072656313741, width: 119.99999999999909, height: 119.99999999999909, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999997726 },
  { x: 531.773380571547, y: 1030.4353896252296, width: 250.32597517021782, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 672.1739881187168, y: 729.0072656313741, width: 119.99999999999909, height: 119.99999999999909, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999997726 },
  { x: 417.5913039787588, y: 902.5514638413065, width: 119.99999999999955, height: 120.00000000000023, angle: 7.5398223686154875, type: 'Saw', radius: 59.999999999998124 },
  { x: 426.7258701061819, y: 1000.7480497111046, width: 120.00000000000017, height: 120.00000000000011, angle: 7.5398223686154875, type: 'Saw', radius: 59.99999999999898 },
  { x: 559.1770789538164, y: 957.3588606058449, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: -84.52854808603024, y: 854.3905062063395, width: 119.99999999999949, height: 120.00000000000011, angle: 7.5398223686154875, type: 'Saw', radius: 60 },
  { x: 164.47145191396976, y: 325.3905062141557, width: 131.76004723287127, height: 50, angle: 1.8849555921538759, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -278.52854808603024, y: 254.39050620633952, width: 107.99081932457955, height: 50.000000000000796, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -45.52854808603024, y: 579.3905062141557, width: 183.31915174834526, height: 50.00000000000091, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -95.52854808603024, y: 421.3905062141557, width: 154.63991387634852, height: 50.00000000000074, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -748.5285480860302, y: -32.60949379366048, width: 30, height: 34.64199999999998, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -710.5285480860302, y: -30.609493793660477, width: 30, height: 34.64199999999998, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -672.5285480860302, y: -30.609493793660477, width: 30, height: 34.64199999999998, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -640.5285480860302, y: -30.609493793660477, width: 30, height: 34.64199999999998, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -600.5285480860302, y: -32.60949379366048, width: 30, height: 34.64199999999998, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: 764.4714519139698, y: -2.6094937936604765, width: 105.45531991207099, height: 50.00000000000001, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 706.4714519139698, y: 191.39050620633952, width: 136.28575582746316, height: 50.000000000000114, angle: 2.0420352248333655, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 750.4714519139698, y: 93.39050620633952, width: 100.18255391646699, height: 50.00000000000023, angle: 1.8849555921538759, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 590.4714519139698, y: 47.39050620633952, width: 120, height: 119.99999999999937, angle: 7.5398223686154875, type: 'Saw', radius: 60 },
  { x: -1025.5285480860302, y: 5.3905062063395235, width: 30, height: 34.642, angle: 0, type: 'Spike', length: 20 },
  { x: 35.47145191396976, y: 7.3905062063395235, width: 47.23412206886883, height: 50.00000000000007, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
];

export const level3 = [
  { x: -2179.2199652591335, y: 1132.2799939725428, width: 699.1466689022927, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1009.8866319258004, y: -19.70052931054488, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: -2485.8866319258004, y: 1096.0895177820667, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -1842.8866319258004, y: 1027.3299986772654, width: 331.7102156445317, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2112.8866319258004, y: 719.3299942503227, width: 500, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2117.118631925792, y: 962.4286823043805, width: 120, height: 119.99999999999159, angle: 54.6008803193916, type: 'Saw', radius: 59.999999999998636 },
  { x: -1618.8866319258004, y: 885.3299986772654, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1397.8866319258004, y: 674.3299942503224, width: 406.2349921875011, height: 50.00000000000091, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1887.8866319258004, y: 646.3300034300319, width: 574.4822499404308, height: 50.00000000000159, angle: -0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1609.8866319258004, y: 648.3300034300319, width: 228.19257537643216, height: 49.99999999999932, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1691.8866319258004, y: 680.3299942503224, width: 119.99999999999773, height: 120.00000000000227, angle: 54.6008803193916, type: 'Saw', radius: 60.00000000000023 },
  { x: -1618.371610557768, y: 359.20082506513654, width: 500.00000000000045, height: 50.000000000000114, angle: 0.47123889803846897, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1919.6866319258006, y: 344.62929832392854, width: 406.7352493078488, height: 50.000000000000455, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1877.8866319258004, y: 499.2994706894551, width: 120.00000000000045, height: 119.99999999999466, angle: 54.6008803193916, type: 'Saw', radius: 59.999999999999545 },
  { x: -1875.8866319258004, y: 383.2994706894551, width: 119.99999999999909, height: 120.00000000000102, angle: 54.6008803193916, type: 'Saw', radius: 59.99999999999977 },
  { x: -2337.8866319258004, y: 489.2994706894551, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2559.8866319258004, y: 69.29947068945512, width: 891.2105239114107, height: 50.000000000000966, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2338.8866319258004, y: 225.32999425032267, width: 299.36846961919036, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2137.8866319258004, y: 351.3299942503225, width: 386.89046874999985, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2335.8866319258004, y: -136.6700057367284, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2023.8866319258004, y: -142.6700057367284, width: 119.99999999999909, height: 119.99999999999625, angle: 54.6008803193916, type: 'Saw', radius: 59.99999999999932 },
  { x: -1391.8866319258004, y: 275.2994706894551, width: 500.0000000000009, height: 50.000000000000455, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1867.8866319258004, y: 275.2994706894551, width: 120.00000000000023, height: 119.99999999999773, angle: 54.6008803193916, type: 'Saw', radius: 59.999999999999886 },
  { x: -2137.8873293778934, y: 172.44962642784049, width: 49.720128493546326, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1183.8866319258004, y: 53.29947068945512, width: 472.6279687500005, height: 50.000000000000064, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1894.3290295127283, y: 1096.5436208529231, width: 30, height: 34.641999999999825, angle: -4.71238898038469, type: 'Spike', length: 20 },
  { x: -1964.7203231637116, y: 1096.5436208529231, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -1929.52467633822, y: 1096.5436208529231, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -1997.2086125410885, y: 1097.8972995769807, width: 30, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -1473.8866319258004, y: 849.3299986772654, width: 30, height: 34.64200000000005, angle: -4.71238898038469, type: 'Spike', length: 20 },
  { x: -1511.8866319258004, y: 847.3299986772654, width: 30, height: 34.64200000000005, angle: -4.71238898038469, type: 'Spike', length: 20 },
  { x: -1438.8866319258004, y: 849.3299986772654, width: 30, height: 34.64200000000005, angle: -4.71238898038469, type: 'Spike', length: 20 },
  { x: -2511.8866319258004, y: 453.32999527988613, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -1955.437612848054, y: 314.52942717124324, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2300.6256874826845, y: 314.52942717124324, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2265.430040657193, y: 314.52942717124324, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2231.588072555758, y: 314.52942717124324, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2197.746104454324, y: 315.88310589530056, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2162.550457628832, y: 318.5904633434153, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2127.3548108033406, y: 317.236784619358, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2093.5128427019063, y: 317.236784619358, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2062.3782320485866, y: 317.236784619358, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2027.182585223095, y: 315.88310589530056, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -1989.2795809494885, y: 315.88310589530056, width: 30, height: 34.64200000000028, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -1799.764559581456, y: 227.89398883157128, width: 30, height: 34.641999999999996, angle: 0, type: 'Spike', length: 20 },
  { x: -1767.276270204079, y: 244.13813352025977, width: 30, height: 34.642000000000024, angle: 0, type: 'Spike', length: 20 },
  { x: -1737.495338274817, y: 260.38227820894826, width: 30, height: 34.642000000000024, angle: 0, type: 'Spike', length: 20 },
  { x: -1705.00704889744, y: 279.3337803457515, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1705.00704889744, y: 279.3337803457515, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1672.518759520063, y: 292.87056758632525, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1644.0915063148582, y: 309.11471227501374, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1608.8958594893666, y: 324.0051782396448, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1575.053891387932, y: 342.95668037644805, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1542.565602010555, y: 356.4934676170218, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1512.784670081293, y: 375.44496975382503, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1481.6500594279732, y: 388.9817569943988, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -1450.5154487746536, y: 405.2259016830873, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2474.8866319258004, y: 453.32999527988613, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2438.8866319258004, y: 455.32999527988613, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -1532.4978534073962, y: 113.3529390682628, width: 49.720128493546326, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1765.2015769832196, y: 129.62698953046765, width: 49.720128493546326, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2340.5587274782392, y: 66.47868258215988, width: 30, height: 34.641999999999825, angle: 7.853981633974482, type: 'Spike', length: 20 },
  { x: -2341.8866319258004, y: -102.6700057367284, width: 30, height: 34.641999999999825, angle: 4.71238898038469, type: 'Spike', length: 20 },
  { x: -2057.8866319258004, y: 653.3299942503226, width: 138.69478656091815, height: 50.000000000000796, angle: 0.47123889803846897, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2067.8866319258004, y: 609.3299942503226, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2037.8866319258004, y: 623.3299942503226, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
  { x: -2011.8866319258004, y: 639.3299942503226, width: 30, height: 34.64200000000005, angle: 0, type: 'Spike', length: 20 },
];

export const level4 = [
  { x: -2645.8866319258004, y: 1271.4525722055532, width: 672.5543349534846, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2144.8592411458794, y: 1087.5512602596139, width: 50.78827814352826, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -819.8866319258004, y: 952.5025725011205, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: -2915.8866319258004, y: 1240.4025719277738, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -2358.661740007053, y: 1023.5512592489958, width: 47.11603676369759, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2146.8592786062445, y: 931.5512602596148, width: 45.04027468264394, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2362.791191204165, y: 869.5512602596157, width: 49.720128493546326, height: 50.00000000000091, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2149.3441459911974, y: 798.5512579730357, width: 52.33697736162685, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2359.8866319258004, y: 731.4525722055532, width: 54.54270205857392, height: 50.00000000000091, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2097.677322959162, y: 799.4560190516081, width: 78.69238337562729, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2359.8866319258004, y: 989.4525722055531, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2363.8866319258004, y: 831.4525722055531, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2361.8866319258004, y: 693.4525722055531, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2149.8866319258004, y: 899.4525722055532, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2145.8866319258004, y: 1051.4525722055532, width: 30.000000000000455, height: 34.641999999999825, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2149.8866319258004, y: 965.4525722055532, width: 30, height: 34.642000000000166, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2143.8866319258004, y: 1119.4525722055532, width: 30.000000000000455, height: 34.641999999999825, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2359.8866319258004, y: 1057.4525722055532, width: 30.000000000000455, height: 34.641999999999825, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2361.8866319258004, y: 903.4525722055532, width: 30, height: 34.642000000000166, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2359.8866319258004, y: 769.4525722055532, width: 30, height: 34.642000000000166, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2153.8866319258004, y: 761.4525722055532, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2117.8866319258004, y: 761.4525722055532, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -2081.8866319258004, y: 761.4525722055532, width: 30, height: 34.642000000000166, angle: -2.670353755551324, type: 'Spike', length: 20 },
  { x: -1791.8866319258004, y: 816.4525722185922, width: 383.9960383855014, height: 50.000000000000455, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1873.9106623427542, y: 939.5512602596118, width: 500, height: 50.00000000000102, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1928.9106623427542, y: 758.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1900.9106623427542, y: 776.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1872.9106623427542, y: 796.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1842.9106623427542, y: 818.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1814.9106623427542, y: 838.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1762.9106623427542, y: 880.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1788.9106623427542, y: 858.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1736.9106623427542, y: 896.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1712.9106623427542, y: 914.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1694.9106623427542, y: 928.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1668.9106623427542, y: 944.5512602596118, width: 30, height: 34.642000000000166, angle: 1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1658.9106623427542, y: 1066.5512602596118, width: 179.24296120427107, height: 50, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1500.8866319258004, y: 1108.4525722185922, width: 145.99451216938633, height: 50.000000000000455, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1366.8866319258004, y: 764.4525722185922, width: 451.2500000000007, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1611.9106623427542, y: 701.5512602596118, width: 500.00000000000045, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2046.8866319258004, y: 536.4525722185922, width: 153.67843386251297, height: 50.000000000001364, angle: 1.0995574287564276, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1978.8866319258004, y: 650.4525722185922, width: 153.67843386251207, height: 50.00000000000023, angle: 0.9424777960769379, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2228.8866319258004, y: 568.4525722185922, width: 119.99999999999955, height: 119.9999999999992, angle: 33.23805027497992, type: 'Saw', radius: 60 },
  { x: -1358.8866319258004, y: 1098.4525722185922, width: 119.99999999999864, height: 119.99999999999864, angle: 33.23805027497992, type: 'Saw', radius: 60 },
  { x: -1526.8866319258004, y: 1094.4525722185922, width: 145.99451216938633, height: 50.000000000000455, angle: 0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2147.8866319258004, y: 1267.4525722055532, width: 120.00000000000182, height: 119.99999999999636, angle: 33.23805027497992, type: 'Saw', radius: 60 },
  { x: -1577.8866319258004, y: 923.4525722055532, width: 30, height: 34.642000000000166, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1575.8866319258004, y: 757.4525722055532, width: 30, height: 34.642000000000166, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1576.8866319258004, y: 605.4525722055532, width: 30, height: 34.642000000000166, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -1402.8866319258004, y: 847.4525722055532, width: 30, height: 34.642000000000166, angle: 2.356194490192345, type: 'Spike', length: 20 },
  { x: -1403.8866319258004, y: 688.5025727117327, width: 30, height: 34.64200000000028, angle: 2.199114857512855, type: 'Spike', length: 20 },
  { x: -1459.8866319258004, y: 309.4525722055532, width: 160.55655229258173, height: 50.00000000000023, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1611.8866319258004, y: 361.4525722055532, width: 119.99999999999864, height: 119.99999999999977, angle: 33.23805027497992, type: 'Saw', radius: 60 },
  { x: -1299.8866319258004, y: 373.4525722055532, width: 243.8374895577649, height: 50.000000000000114, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1324.8866319258004, y: 563.5061642166161, width: 131.76004723287133, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1172.2481999111178, y: 489.81731374996855, width: 145.99451216938655, height: 50.000000000000966, angle: 0.9424777960769379, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1082.41044144961, y: 724.9787629609069, width: 448.4256123619016, height: 50, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -843.8866319258004, y: 1028.5025725011205, width: 118.61615902109702, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1017.648536687705, y: 1208.7882867704307, width: 119.99999999999818, height: 119.99999999999841, angle: 33.23805027497992, type: 'Saw', radius: 60 },
  { x: -1213.8390128781814, y: 761.1692391513832, width: 448.4256123619016, height: 49.999999999999545, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
];

export const level5 = [
  { x: -1749.3149642132848, y: 725.5851502468622, width: 1638.9671073679294, height: 100.36681702572855, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3295.594011326464, y: 1355.3403590766698, width: 658.4095617157554, height: 100.3668170257206, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3033.8866319258004, y: 861.4525913714325, width: 794.3136734331929, height: 100.3668170257206, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3029.8866319258004, y: 1853.4525913714324, width: 794.3136734331929, height: 100.3668170257206, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2780.08381014759, y: 1595.5200608181171, width: 536.9391022178015, height: 100.36681702571877, angle: -3.141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2558.8866319258004, y: 1023.4525913714324, width: 1138.8362523042952, height: 100.3668170257206, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1016.1346268628449, y: 351.39714898112334, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: -3297.8866319258004, y: 1019.4525913714325, width: 50, height: 9.999999999999886, angle: 0, type: 'Spawner' },
  { x: -2801.8866319258004, y: 1463.8460577061824, width: 44.87241596542481, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2797.8866319258004, y: 1169.8460577061824, width: 170.28081314405972, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3159.933432068866, y: 1617.6174789746933, width: 120.00000000000091, height: 120, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -2961.344930047524, y: 1535.7437776121378, width: 30.000000000000455, height: 34.641999999999825, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2929.369951549207, y: 1535.7437776121378, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2898.91759107462, y: 1535.7437776121378, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2862.374758505115, y: 1535.7437776121378, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2831.922398030528, y: 1531.1759235409497, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2804.5152736033992, y: 1534.2211595884085, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2781.6760032474585, y: 1534.2211595884085, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2755.791496844059, y: 1534.2211595884085, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2725.339136369472, y: 1532.698541564679, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2704.022484037261, y: 1532.698541564679, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2681.18321368132, y: 1532.698541564679, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2653.776089254192, y: 1532.698541564679, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -2632.4594369219803, y: 1531.1759235409497, width: 30, height: 34.641999999999825, angle: -0.6283185307179586, type: 'Spike', length: 20 },
  { x: -3235.260251630659, y: 1050.4915891510168, width: 30, height: 34.641999999999825, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -3233.737633606929, y: 1088.557039744251, width: 30, height: 34.641999999999825, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -3233.737633606929, y: 1131.1903444086734, width: 30, height: 34.641999999999825, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -3235.260251630659, y: 1175.346267096825, width: 30, height: 34.641999999999825, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -3233.737633606929, y: 1216.4569537375178, width: 30, height: 34.641999999999825, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -3093.6567754238276, y: 1182.9593572154718, width: 30, height: 34.641999999999825, angle: 0, type: 'Spike', length: 20 },
  { x: -3094.6567754238276, y: 1217.9795717612471, width: 30, height: 34.641999999999825, angle: 0, type: 'Spike', length: 20 },
  { x: -3053.5460887831346, y: 1269.7485845680458, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -3007.8675480712536, y: 1269.7485845680458, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -3052.0234707594054, y: 1444.849657296923, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -3010.912784118712, y: 1444.849657296923, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2796.2236427728712, y: 1266.703348520587, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2796.2236427728712, y: 1074.8534775306866, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2906.102618633193, y: 1109.0817163900613, width: 46.646640304527864, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2800.456544146984, y: 854.694206503443, width: 170.28081314405972, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2796.8569474372935, y: 952.3712885985082, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2799.9021834847526, y: 758.9987995848785, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2699.487432417671, y: 792.2717641929275, width: 39.993663231093706, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2697.8867758948845, y: 890.8140351612799, width: 119.99999999999909, height: 120, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -2898.2315639202207, y: 1220.244246049916, width: 120.00000000000091, height: 120, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -2798.8866319258004, y: 465.4525913714324, width: 327.98792125250475, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2798.488780468741, y: 640.2079655749487, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -3199.8866319258004, y: 930.1855784965709, width: 292.6996455070812, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2897.8866319258004, y: 560.4525913714324, width: 119.99999999999909, height: 120.00000000000006, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -2579.8866319258004, y: 443.4525913714324, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2539.8866319258004, y: 445.4525913714324, width: 30, height: 34.641999999999825, angle: 1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2560.768083229883, y: 157.69543669974183, width: 239.29751855858376, height: 100.3668170257206, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2581.8866319258004, y: 288.4525913714324, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2543.8866319258004, y: 288.4525913714324, width: 30, height: 34.641999999999825, angle: -1.5707963267948966, type: 'Spike', length: 20 },
  { x: -2351.8866319258004, y: 490.4525913714324, width: 44.87241596542481, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2145.593493815917, y: 492.20339977891024, width: 44.872415965425716, height: 49.99999999999983, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1918.8866319258004, y: 488.4525913714324, width: 44.87241596542526, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1707.9236338512092, y: 491.00198666139823, width: 44.87241596542526, height: 50.000000000000114, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2257.277363960137, y: 492.20339977891024, width: 119.99999999999909, height: 119.99999999999852, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -1814.032004325264, y: 488.7132788369034, width: 120.00000000000091, height: 119.99999999999972, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -1481.0657726207623, y: 491.00198666139823, width: 44.87241596542526, height: 49.999999999999886, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1372.126962947546, y: 492.7470471324017, width: 120.00000000000068, height: 119.99999999999977, angle: 8.042477193189852, type: 'Saw', radius: 60 },
  { x: -1272.65851610035, y: 492.7470471324017, width: 44.87241596542526, height: 49.99999999999983, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -1021.6247478048517, y: 427.1798097052745, width: 138.69478656091724, height: 50.00000000000034, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2698.8866319258004, y: 240.18557849817716, width: 120, height: 120, angle: 0, type: 'Saw', radius: 60 },
];

export const level6 = [
  { x: -2961.8866319258004, y: 1304.8142451802387, width: 604.7181578320324, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2591.314132959942, y: 1304.9629335120756, width: 119.9999999999809, height: 119.99999999998226, angle: 413.936248036939, type: 'Saw', radius: 60 },
  { x: -2851.0679848178333, y: 1502.9629335120753, width: 606.2084323554172, height: 50.000000000001364, angle: 3.141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2745.8866319258004, y: 1140.8142451802387, width: 125.17204487122763, height: 50, angle: -0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2601.8866319258004, y: 1124.8142451802387, width: 188.6768012676539, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2495.8866319258004, y: 1154.8142451802387, width: 131.76004723287133, height: 50.000000000000455, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2447.8866319258004, y: 1232.8142451802387, width: 131.76004723287133, height: 50, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2431.8866319258004, y: 1322.8142451802387, width: 131.76004723287133, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2453.8866319258004, y: 1414.8142451802387, width: 131.76004723287133, height: 50, angle: 2.0420352248333655, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2606.322948600007, y: 1659.4043485534858, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: -3165.8866319258004, y: 1274.8142451802387, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -2519.8866319258004, y: 1480.8142451802387, width: 131.76004723287133, height: 50, angle: 2.670353755551324, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3236.481732068345, y: 1210.4737866209746, width: 151.76703435909258, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3371.0679848178333, y: 1398.9629335120756, width: 131.76004723287133, height: 50, angle: 2.0420352248333655, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3214.0679848178333, y: 1500.9629335120753, width: 120.00000000000182, height: 119.99999999998681, angle: 413.936248036939, type: 'Saw', radius: 60 },
  { x: -3407.2162487689666, y: 2016.2458480247878, width: 1156.0692704620565, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3372.3557113405095, y: 2396.368781943138, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -3369.3557113405095, y: 1756.368781943138, width: 30, height: 34.641999999999825, angle: -3.141592653589793, type: 'Spike', length: 20 },
  { x: -3373.3557113405095, y: 1794.368781943138, width: 30, height: 34.641999999999825, angle: -3.141592653589793, type: 'Spike', length: 20 },
  { x: -3375.3557113405095, y: 1834.368781943138, width: 30, height: 34.641999999999825, angle: -3.141592653589793, type: 'Spike', length: 20 },
  { x: -3254.0516721062295, y: 2052.384513778531, width: 30, height: 34.641999999999825, angle: 0.15707963267948966, type: 'Spike', length: 20 },
  { x: -3252.041634480838, y: 2086.555153410192, width: 30, height: 34.641999999999825, angle: 0.15707963267948966, type: 'Spike', length: 20 },
  { x: -3201.0888161085004, y: 2743.689216276128, width: 500, height: 50, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3304.6889464852147, y: 2610.9042012705454, width: 119.99999999997817, height: 119.99999999998636, angle: 413.936248036939, type: 'Saw', radius: 60 },
  { x: -3247.0005624535543, y: 2125.498658574091, width: 30, height: 34.641999999999825, angle: 0.15707963267948966, type: 'Spike', length: 20 },
  { x: -3374.3557113405095, y: 2322.368781943138, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -3372.3557113405095, y: 2358.368781943138, width: 30, height: 34.641999999999825, angle: 3.141592653589793, type: 'Spike', length: 20 },
  { x: -3218.3557113405095, y: 2296.368781943138, width: 92.0129551177888, height: 50.00000000000182, angle: -1.413716694115407, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2849.2906014453465, y: 2488.5946280489393, width: 263.8255142108428, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2383.8133383262048, y: 2334.168787613129, width: 206.7141937661945, height: 49.99999999999909, angle: -0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2864.507329484782, y: 2060.021879381443, width: 655.0942780999931, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2790.8720927107597, y: 1737.3250982891368, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3201.2991208014846, y: 2482.8008647844445, width: 30.000000000000455, height: 34.64199999999937, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: -2204.343689054023, y: 1958.5321323549292, width: 110.72808590767772, height: 50.00000000000091, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2991.972011961856, y: 2490.6014856042293, width: 30.000000000000455, height: 34.64199999999937, angle: 4.241150082346221, type: 'Spike', length: 20 },
  { x: -2252.4203528825656, y: 2196.385100769824, width: 30, height: 34.64200000000028, angle: 0.9424777960769379, type: 'Spike', length: 20 },
  { x: -3081.4561680426887, y: 2519.1282141509723, width: 119.99999999997817, height: 119.99999999998636, angle: 413.936248036939, type: 'Saw', radius: 60 },
  { x: -2582.8156681912415, y: 2466.388383611097, width: 119.99999999997726, height: 119.99999999997726, angle: 413.936248036939, type: 'Saw', radius: 60 },
  { x: -2222.056144148749, y: 2138.1870340300093, width: 110.72808590767545, height: 50.00000000000091, angle: 2.0420352248333655, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2525.4641418718816, y: 2057.8858824735134, width: 30.000000000000455, height: 34.6419999999996, angle: 5.183627878423159, type: 'Spike', length: 20 },
  { x: -2207.0329356104494, y: 1892.4321955742373, width: 30, height: 34.641999999999825, angle: 3.612831551628262, type: 'Spike', length: 20 },
  { x: -2295.1784157257252, y: 2262.449937606885, width: 30, height: 34.64200000000028, angle: 2.5132741228718345, type: 'Spike', length: 20 },
  { x: -3025.919425337318, y: 2059.612931564897, width: 72.36424928501492, height: 50.000000000003865, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3104.8906486274946, y: 2014.475566280718, width: 114.81843246252902, height: 50.000000000003865, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3172.6797675507637, y: 1948.2774995409036, width: 114.81843246252902, height: 50.00000000000409, angle: 1.0995574287564276, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3213.69572992367, y: 1910.731186440179, width: 738.7277218945292, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3200.513625556762, y: 1857.1848733394545, width: 114.81843246252902, height: 50.00000000000455, angle: 1.413716694115407, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -2204.0857895242766, y: 2022.0666184641723, width: 30, height: 34.641999999999825, angle: 4.71238898038469, type: 'Spike', length: 20 },
  { x: -2474.8333174008058, y: 2399.088876909059, width: 30.000000000000455, height: 34.64199999999937, angle: 3.612831551628262, type: 'Spike', length: 20 },
  { x: -2706.042379718419, y: 2488.071134876411, width: 30, height: 34.64200000000028, angle: 5.340707511102648, type: 'Spike', length: 20 },
  { x: -2194.222286142751, y: 2077.4586165623764, width: 30, height: 34.64200000000028, angle: 4.084070449666731, type: 'Spike', length: 20 },
  { x: -3018.481732068345, y: 1160.4737866209746, width: 451.25, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -3305.0679848178333, y: 1326.9629335120756, width: 131.76004723287133, height: 50, angle: 2.670353755551324, type: 'Platform', moveOverX: 0, moveOverY: 0 },
];

export const level7 = [
  { x: 838.9909933314711, y: -437.07726569232955, width: 1146.009158900517, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -175.78386015364777, y: -14.274673706612361, width: 50, height: 10, angle: 0, type: 'Spawner' },
  { x: -15.009006668528741, y: -398.3581260663793, width: 420.1883963807889, height: 50.000000000000114, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 290.21613984635223, y: -272.35812606638115, width: 381.1232620233908, height: 50, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -7.783860153647765, y: 17.72532629338764, width: 435.710349509812, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -15.783860153647765, y: -176.35812606638046, width: 30.000000000000004, height: 34.641999999999996, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: 292.21613984635223, y: 3.7253262933876385, width: 119.99999999999517, height: 119.99999999999608, angle: 95.94423964063537, type: 'Saw', radius: 60 },
  { x: 1708.9909933314711, y: -750.9285789260757, width: 153.67843386251297, height: 50.000000000000455, angle: -1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1476.9909933314711, y: -446.9285789260757, width: 153.6784338625116, height: 50, angle: -0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1608.9909933314711, y: -496.9285789260757, width: 153.6784338625116, height: 50.00000000000006, angle: -0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1686.9909933314711, y: -606.9285789260757, width: 153.6784338625116, height: 50, angle: -1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 960.9111154179036, y: -700.7337856593168, width: 1039.4640897056806, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1487.6419364226645, y: -706.7798916661162, width: 30, height: 34.64200000000005, angle: -1.0995574287564276, type: 'Spike', length: 20 },
  { x: -21.009006668528855, y: -686.9285789260757, width: 119.99999999999696, height: 119.99999999999454, angle: 95.94423964063537, type: 'Saw', radius: 60 },
  { x: 322.52677292097314, y: -1255.2737900277443, width: 470.8468929799965, height: 50.000000000000455, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 378.64193642266446, y: -728.7798916661163, width: 186.33010410796953, height: 50, angle: 0.3141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 240.64193642266446, y: -808.7798916661163, width: 186.33010410796953, height: 50, angle: 0.7853981633974483, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 154.64193642266446, y: -946.7798916661163, width: 186.3301041079695, height: 50, angle: 1.2566370614359172, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 4.130745953620362, y: -1360.0466968178632, width: 29.99999999999998, height: 34.641999999999825, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -121.53797302117766, y: -1106.3627018378331, width: 100.68535138997072, height: 50.000000000000455, angle: 3.141592653589793, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 22.130745953620362, y: -1180.0466968178632, width: 29.99999999999998, height: 34.641999999999825, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: 132.64193642266446, y: -1490.7798916661163, width: 186.33010410796942, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 134.64193642266446, y: -1388.7798916661163, width: 30.000000000000085, height: 34.641999999999825, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: 128.64193642266446, y: -1110.7798916661163, width: 186.33010410796942, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 130.64193642266446, y: -1214.7798916661163, width: 30.000000000000085, height: 34.641999999999825, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: 4.130745953620362, y: -1464.0466968178632, width: 186.33010410796942, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 22.130745953620362, y: -1076.0466968178632, width: 186.33010410796942, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -249.53797302117766, y: -1104.3627018378331, width: 119.99999999999216, height: 119.99999999999909, angle: 95.94423964063537, type: 'Saw', radius: 60 },
  { x: -157.53797302117766, y: -1494.3627018378331, width: 349.1686480468743, height: 50.000000000000455, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -365.53797302117766, y: -1352.3627018378331, width: 349.16864804687475, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -591.5379730211775, y: -1501.3627018378331, width: 500, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: -157.53797302117755, y: -1311.3627018378331, width: 29.99999999999997, height: 34.641999999999825, angle: 0.47123889803846897, type: 'Spike', length: 20 },
  { x: -779.5379730211775, y: -1577.3627018378331, width: 50, height: 100, angle: 0, type: 'Checkpoint' },
  { x: 1234.5652708530442, y: -968.2756756800486, width: 135.6051788343011, height: 50, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1209.7080322524696, y: -711.0705819025435, width: 135.60517883429975, height: 50.00000000000023, angle: 0.15707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1138.1363164507452, y: -750.1523513319361, width: 135.60517883429975, height: 50, angle: 0.6283185307179586, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1083.605485363717, y: -830.2445094910087, width: 135.60517883429975, height: 50, angle: 1.413716694115407, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1040.2991851055067, y: -876.9589866921583, width: 118.91344262766631, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 877.7066918444222, y: -957.0511448512309, width: 137.31303108679708, height: 50.00000000000193, angle: 2.199114857512855, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1130.9117856219273, y: -1059.2964531394086, width: 256.67104163975273, height: 50, angle: 0, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1010.6255926190532, y: -812.2036247763124, width: 179.24296120427255, height: 50.00000000000216, angle: 1.5707963267948966, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 957.7988500034947, y: -1028.6228606529553, width: 137.31303108679754, height: 49.999999999999545, angle: 2.670353755551324, type: 'Platform', moveOverX: 0, moveOverY: 0 },
  { x: 1008.9215041475836, y: -911.0407561215509, width: 30, height: 34.64200000000005, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: 1075.380954534899, y: -911.0407561215509, width: 30, height: 34.64200000000005, angle: -0.47123889803846897, type: 'Spike', length: 20 },
  { x: 1041.2991851055067, y: -911.0407561215509, width: 30, height: 34.64200000000005, angle: -0.47123889803846897, type: 'Spike', length: 20 },
];
