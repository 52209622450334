import { Body } from 'matter-js';
import * as v4 from 'uuid/v4';

import Game from './game';
import Checkpoint from './game/checkpoint';
import { level0, level1, level2, level3, level4, level5, level6, level7 } from './game/levels';
import Platform from './game/platform';
import Saw from './game/saw';
import Spawner from './game/spawner';
import Spike from './game/spike';

// import { State } from './index';

export default class Manager {
  public static state = {};

  public game: Game;

  public container: HTMLDivElement;

  public canvas: HTMLCanvasElement;

  public debug = false;

  public toggleFade: any;
  public toggleMenu: any;
  public toggleDebug: any;

  public levels: any[] = [];
  public levelIndex: number = 0;

  public profile: { username: string; uuid: string; lastLevelIndex } = { username: 'anon', uuid: '', lastLevelIndex: 0 };

  public nextLevelTimeout;

  // tslint:disable-next-line: max-line-length
  constructor(
    opts: { state: any; container: HTMLDivElement; canvas: HTMLCanvasElement; debug: boolean; toggleFade: any; toggleMenu: any; toggleDebug: any } = {
      state: undefined,
      container: null,
      canvas: null,
      debug: false,
      toggleFade: null,
      toggleMenu: null,
      toggleDebug: null,
    },
  ) {
    this.canvas = opts.canvas;
    this.container = opts.container;
    this.debug = opts.debug;
    this.toggleFade = opts.toggleFade;
    this.toggleMenu = opts.toggleMenu;
    this.toggleDebug = opts.toggleDebug;

    if (opts.state) {
      Manager.state = opts.state;
    }

    (window as any).toggleDebug = () => {
      // problem comes from the singleton render, world, ....
      // camera bounds do not get updated unless we pause => play
      this.game.pause();
      this.toggleDebug();
      this.container.innerHTML = null;
      this.debug = !this.debug;
      this.game = new Game({ debug: this.debug, container: this.container, manager: this });
      this.loadLevel(this.levelIndex);

      this.play();
    };
    (window as any).saveLevel = this.saveLevel;
    (window as any).loadLevel = this.loadLevel;

    window.requestAnimationFrame = (() =>
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      (window as any).mozRequestAnimationFrame ||
      (window as any).window.msRequestAnimationFrame ||
      (window as any).window.oRequestAnimationFrame ||
      (f => window.setTimeout(f, 1e3 / 60)))();

    this.listeners();
    this.init();
  }

  listeners = () => {
    window.addEventListener('keydown', e => {
      switch (e.which) {
        case 33:
          this.debug && this.previousLevel();
          break;
        case 34:
          this.debug && this.nextLevel();
          break;
        case 220:
          if (this.debug) {
            console.log('level added');
            this.levels.push([]);
          }
      }
    });
  };

  play = () => {
    this.loadLevel(this.levelIndex);
    this.game.resume();
    this.container.focus();
  };

  finish = () => {
    if (this.profile.lastLevelIndex < this.levelIndex) {
      this.profile.lastLevelIndex = this.levelIndex;

      localStorage.setItem('lastLevelIndex', `${this.levelIndex}`);
    }

    // console.log(this.levelIndex, this.levels.length);
    if (this.levelIndex === this.levels.length - 1) {
      // TOGGLING MENU
      this.toggleMenu();
      this.levelIndex = 0;
      setTimeout(() => alert('Thanks for playing! Please let me know what you thought about this small demo!'), 1000);
    } else {
      this.fade();
      // if (this.nextLevelTimeout === 0) {
      this.nextLevelTimeout = setTimeout(() => {
        this.nextLevel();
        this.fade();
      }, 1000);
    }
    // }
  };

  // TODO SET PROFILE

  init = () => {
    if (!localStorage.getItem('session')) {
      const uuid = v4();
      this.profile.uuid = uuid;
      localStorage.setItem('session', uuid);
    } else {
      this.profile.uuid = localStorage.getItem('session');
    }

    if (!localStorage.getItem('username')) {
      const username = prompt(`Would you like to set a username? Highscores will be added later!`);
      if (username) {
        this.profile.username = username;
        localStorage.setItem('username', username);
      }
    } else {
      this.profile.username = localStorage.getItem('username');
    }

    if (localStorage.getItem('lastLevelIndex')) {
      this.profile.lastLevelIndex = Number(localStorage.getItem('lastLevelIndex'));
      this.levelIndex = this.profile.lastLevelIndex;
    }

    this.levels = [level0, level1, level7, level2, level4, level3, level6, level5];
    this.game = new Game({ debug: this.debug, container: this.container, manager: this });
  };

  // public toggleDebug = () => {
  //   // this.container.innerHTML = null;
  //   // this.debug = !this.debug;
  //   // this.game = new Game({ debug: this.debug, container: this.container, manager: this });
  //   // this.loadLevel(this.levelIndex);
  // };

  public fade = () => {
    this.toggleFade();
  };

  public previousLevel() {
    if (this.levels[this.levelIndex - 1]) {
      this.loadLevel(this.levelIndex - 1);
    }
  }

  public nextLevel() {
    if (this.levels[this.levelIndex + 1]) {
      this.loadLevel(this.levelIndex + 1);
      // this.game.reset();
    }
    //  else {
    //   // this.toggleMenu();

    // }
  }

  public reset = () => {
    // clearInterval(this.nextLevelTimeout);
    // this.nextLevelTimeout = 0;

    this.game.reset();
  };

  public loadLevel = (levelIndex?) => {
    this.game.clear();

    if (!this.levels || !this.levels[levelIndex]) {
      console.log('Level not found!');
    }

    // otherwise its false
    if (levelIndex !== undefined) {
      this.levelIndex = levelIndex;
    }

    if (this.levels && this.levels[this.levelIndex] && this.levels[this.levelIndex].length > 0) {
      for (const schema of this.levels[this.levelIndex]) {
        let entity;
        switch (schema.type) {
          case 'Platform':
            entity = new Platform({ ...schema });
            break;
          case 'Saw':
            entity = new Saw({ ...schema, radius: schema.radius ? schema.radius : (schema.width * 2) / 4 });
            break;
          case 'Spawner':
            entity = new Spawner({ ...schema });
            break;
          case 'Checkpoint':
            // entity = new Checkpoint(schema.x, schema.y);
            entity = new Checkpoint({ ...schema });
            break;
          case 'Spike':
            entity = new Spike({ ...schema });
            break;
        }
        if (entity) {
          this.game.entities.push(entity);
        }
      }
      this.reset();
    }
  };

  // print and save to localStorage
  public saveLevel = (levelIndex: number) => {
    const entities = this.game.entities.map(entity => {
      const {
        body: {
          angle,
          position: { x, y },
        },
      } = entity;
      Body.setAngle(entity.body, 0);
      const width = entity.body.bounds.max.x - entity.body.bounds.min.x;
      const height = entity.body.bounds.max.y - entity.body.bounds.min.y;
      Body.setAngle(entity.body, angle);
      return {
        x,
        y,
        width,
        height,
        angle,
        type: entity.constructor.name,
        ...(entity instanceof Platform ? { moveOverX: entity.moveOverX, moveOverY: entity.moveOverY } : {}),
        ...(entity instanceof Saw ? { radius: entity.radius } : {}),
        ...(entity instanceof Spike ? { length: entity.length } : {}),
      };
    });
    this.levels = JSON.parse(localStorage.getItem('levels'));
    this.levels[levelIndex ? levelIndex : this.levelIndex] = entities;
    localStorage.setItem('levels', JSON.stringify(this.levels));
    console.log(JSON.stringify(this.levels[this.levelIndex]));
  };
}
