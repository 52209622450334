import { Bodies, Body, World } from 'matter-js';

import { engine } from './game';

export default class Spike {
  public body: Body;
  public bodies: Body[];
  public length;
  public angle;

  constructor(opts: { x; y; length; angle?: 0 }) {
    this.angle = opts.angle ? opts.angle : 0;
    this.length = opts.length ? opts.length : 20;

    this.body = Bodies.polygon(opts.x, opts.y, 3, this.length, {
      isStatic: true,
      // angle: (Math.random() * Math.PI * 2) / 5,
      angle: this.angle,
      label: 'Spike',
      render: {
        fillStyle: 'grey',
      },
      // }),
    });

    World.add(engine.world, this.body);
  }

  public update() {
    // Body.rotate(this.body, Math.PI / 50);
    // this.body.angle += Math.PI / 20;
  }
}
